import { useRef, useState } from 'react';
import Link from 'next/link';
import { paths } from 'routes/index';
import { useOnClickOutside } from '@/hooks/index';
import { CoverRoverLogo } from '@/components/CoverRoverLogo/CoverRoverLogo';
import { PopUp } from '@/components/AdvertisingDisclosure/AdvertisingDisclosure';

export const Footer = (): JSX.Element => {
  const date = new Date();
  const year = date.getFullYear();
  const [popUp, togglePopUp] = useState(false);
  const ref = useRef(null);
  const closePopUp = () => togglePopUp(false);
  useOnClickOutside(ref, closePopUp);

  return (
    <footer className="pt-8 bg-footerBg">
      <div
        id="footer_logo"
        className="flex items-center justify-center md:mb-9"
      >
        <CoverRoverLogo
          src={`/atomicImages/CoverRover/layout/header/header_logo_white.svg`}
          width={247}
          height={52}
        />
      </div>
      <nav className="mb-6">
        <div className="flex flex-col md:flex-row md:items-start items-center justify-center font-lato">
          <div className="md:py-0 md:flex py-4 justify-center">
            <Link
              href={paths.faqs}
              id="footer_faq"
              className="text-footerFg mx-8"
            >
              FAQ
            </Link>
            <div className="md:block hidden h-21px border-r-2 border-footerFg mx-3"></div>
          </div>

          <div className="md:py-0 md:flex py-4">
            <Link
              href={paths.terms}
              id="footer_terms"
              className="text-footerFg mx-8"
            >
              Terms of use
            </Link>
            <div className="md:block hidden h-21px  border-r-2 border-footerFg mx-3"></div>
          </div>

          <div className="md:py-0 md:flex py-4">
            <Link
              href={paths.privacy}
              id="footer_privacy"
              className="text-footerFg mx-8"
            >
              Privacy policy
            </Link>
            <div className="md:block hidden h-21px  border-r-2 border-footerFg mx-3"></div>
          </div>

          <div className="md:py-0 md:flex py-4 relative">
            {popUp && (
              <div ref={ref}>
                <PopUp closePopUp={closePopUp} />
              </div>
            )}

            <span
              id="footer_disclouse"
              onClick={() => {
                return togglePopUp(true);
              }}
              className="cursor-pointer text-footerFg mx-8"
            >
              Advertising Disclosure
            </span>
          </div>
        </div>
      </nav>
      <div className="bg-footerBg text-center text-sm text-footerFg font-lato pb-[20px]">
        CoverRover © {year} - All rights reserved
      </div>
    </footer>
  );
};
