import { InputProps } from './Input.props';

export const Input = ({
  name,
  type,
  value,
  register,
  track_id,
  maxLength,
  styles = '',
  borderLight,
  defaultValue,
  errorText = '',
  errors = false,
  inputTextStyles,
  placeholder = '',
  disabled = false,
  customPlaceholder,
}: InputProps): JSX.Element => {
  const invalid = errors && `!border-red-dark ${!borderLight && 'border-2'} `;

  const maxLengthCheck = (e: any) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }
  };

  const inputTextStyle = {
    fontSize: '14px',
    width: '200px',
    height: '35px',
  };
  const style = inputTextStyles || inputTextStyle;
  return (
    <div
      className="inputText mb-[20px] flex flex-col items-center font-lato"
      style={style}
      id={track_id}
    >
      <input
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        aria-label={placeholder}
        inputMode={type === 'number' ? 'numeric' : 'text'}
        onInput={(e) => (maxLength ? maxLengthCheck(e) : null)}
        className={` ${invalid} border border-gray px-4 pt-4 pb-2 focus:bg-white text-gray-900 focus:${
          borderLight && 'border-4'
        } focus:border-blue-dark 
        focus:ring-0 outline-none disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${styles} min-h-[55px] max-w-[250px] md:min-h-[80px] md:min-w-[200px] br-[4px]  shadow-[0px_8px_50px_0px_#BCBCBC] bg-FEFEFE border-[1px] border-[#2f2f2f] border-solid mt-[20px] md:mt-0`}
        {...register}
      />
      <span
        className={`${(value || defaultValue) && 'transformed-label'} ${
          errors && 'text-red-dark'
        } text-grey ${
          customPlaceholder && !value
            ? 'floating-label-new text-lg font-bold font-lato text-[#2f2f2f]'
            : 'floating-label'
        } pl-[100px] pt-[20px] md:pt-[15px] md:pl-[25px]`}
      >
        {placeholder}
      </span>

      <div className="text-red-dark text-xs mt-1 text-start">
        {errors && errorText}
      </div>
    </div>
  );
};
