import { useState } from 'react';
import { motion } from 'framer-motion';
import { CustomSelectorProps } from './CustomSelector.props';

export const CustomSelector = ({
  width,
  register,
  getValue,
  options = [],
  selectedOptionId,
}: CustomSelectorProps) => {
  const [checkedOptionId, setCheckedOptionId] = useState(
    selectedOptionId || null
  );
  const getCheck = (id: number, value: string) => {
    setCheckedOptionId(id);
    getValue && getValue(value);
  };

  return (
    <div
      style={{
        width: `${width}px`,
      }}
      className="flex font-roboto"
    >
      {options.map((option, index) => {
        return (
          <motion.div
            key={option.id}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            style={{ width: '100%' }}
            className="flex"
            id={option.trakId}
          >
            <input
              type="radio"
              value={option.name}
              name={option.name}
              key={option.id}
              id={option.id.toString()}
              onClick={() => {
                return getCheck(option.id, option.value);
              }}
              checked={checkedOptionId === option.id}
              {...register}
            />
            <label
              className={`${
                index === 0 ? 'mr-1' : 'ml-1'
              } flex items-center w-full text-grey-lighten justify-center py-2 px-4 cursor-pointer rounded-3xl border-[1.5px] border-gray flex-auto`}
              htmlFor={option.id.toString()}
            >
              {option.value}
            </label>
          </motion.div>
        );
      })}
    </div>
  );
};
