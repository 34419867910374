import Image from 'next/image';

export const PopUp = ({ closePopUp, isHeader }: any) => {
    if (isHeader) {
        return (
            <div
              style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
              className="rounded-tl-[12px] rounded-tr-[12px] bg-white border border-primary absolute flex flex-col items-center pt-[22px] pr-[23px] pl-[22px] pb-[12px] text-[10px] top-[110px] md:top-[60px] left-[20%] md:left-[60%] lg:left-[70%] xl:left-[82%]"
            >
                <div
                style={{
                  content: '',
                  top: '-8%',
                  left: '55%',
                }}
                className="absolute ml-[-20px] md:ml-[70px] lg:ml-[60px] xl:ml-[-110px]"
              >
                <Image
                  src="/triangleWithShadow.png"
                  alt="as"
                  width={41}
                  height={29}
                  className="mb-6"
                  style={{transform: "rotate(180deg)"}}
                />
              </div>
              <span className="w-[240px]">
                CoverRover.com may earn a commission when a user completes an action
                using our links, which will however not affect the review but might
                affect the rankings. The latter is determined on the basis of product
                market fit and compensation received. The information contained in
                CoverRover.com should not be conceived as legal, financial or investment
                advice nor as an endorsement of any of the products or services
                referenced in CoverRover.com. As disclosed in the Terms and Conditions
                all information on CoverRover.com is subject to change. CoverRover.com
                does not contain an exhaustive list of all lending or insurance partners
                in each category.
              </span>
              <span
                onClick={() => closePopUp()}
                className="mt-[15px] cursor-pointer font-roboto text-[10px]"
              >
                CLOSE
              </span>
            </div>
          );
    }

    else {
        return (
            <div
              style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
              className="rounded-bl-[12px] rounded-br-[12px] bg-white border border-primary absolute flex flex-col items-center pt-[22px] pr-[23px] pl-[22px] pb-[12px] text-[10px] top-[-250px] md:top-[-270px] left-[-30px] md:left-[-160px] lg:left-[-50px] xl:left-[50px]"
            >
              <span className="w-[240px]">
                CoverRover.com may earn a commission when a user completes an action
                using our links, which will however not affect the review but might
                affect the rankings. The latter is determined on the basis of product
                market fit and compensation received. The information contained in
                CoverRover.com should not be conceived as legal, financial or investment
                advice nor as an endorsement of any of the products or services
                referenced in CoverRover.com. As disclosed in the Terms and Conditions
                all information on CoverRover.com is subject to change. CoverRover.com
                does not contain an exhaustive list of all lending or insurance partners
                in each category.
              </span>
              <span
                onClick={() => closePopUp()}
                className="mt-[15px] cursor-pointer font-roboto text-[10px]"
              >
                CLOSE
              </span>
              <div
                style={{
                  content: '',
                  top: '97.6%',
                  left: '50%',
                }}
                className="absolute ml-[-20px] md:ml-[70px] lg:ml-[60px] xl:ml-[-110px]"
              >
                <Image
                  src="/triangleWithShadow.png"
                  alt="as"
                  width={41}
                  height={29}
                  className="mb-6"
                />
              </div>
            </div>
          );
    }
    
  };