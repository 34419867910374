import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext({
  theme: 'theme-wagmo',
  setTheme: (theme: string) => {},
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<string>('theme-wagmo');

  useEffect(() => {
    const localTheme = localStorage.getItem('theme');
    if (localTheme) {
      setTheme(localTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <body data-theme={`${theme}`}>
        <div className={`${theme}`}>{children}</div>
      </body>
    </ThemeContext.Provider>
  );
};
