import { useEffect, useState, useCallback, useRef } from 'react';
import { InputProps } from './InputNew.props';

interface ExtendedInputProps extends InputProps {
  width?: string;
  height?: string;
  fontFamily?: string;
  fontWeight?: string | number;
}

export const InputNew = (props: ExtendedInputProps): JSX.Element => {
  const {
    name,
    type,
    value,
    register,
    track_id,
    maxLength,
    styles,
    borderLight,
    defaultValue,
    errorText = '',
    errors = false,
    inputTextStyles,
    placeholder = '',
    disabled = false,
    customPlaceholder,
    width = '192px',
    height = '92px',
    fontFamily = 'Lato',
    fontWeight = '700',
  } = props;

  const [windowWidth, setWindowWidth] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        if (!value) {
          setIsFocused(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [value]);

  const heightValue = windowWidth < 768 ? '46px' : height;

  const maxLengthCheck = useCallback(
    (e: any) => {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
    },
    [maxLength]
  );

  const inputTextStyle = {
    fontSize: '23px',
    width: width,
    height: heightValue,
    color: '#4A4A4A',
    textAlign: 'center',
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    lineHeight: '150%',
    letterSpacing: '0.69px',
    textTransform: 'uppercase',
  };

  const style: any = inputTextStyles || inputTextStyle;
  const placeholderFontSize = windowWidth < 768 ? '16px' : '18px';
  const placeholderTop =
    windowWidth < 768 && (value || isFocused) ? '-9px' : '0px';

  return (
    <div
      style={{
        position: 'relative',
        width: style.width || '295px',
        fontSize: style.fontSize || '14px',
        fontFamily: style.fontFamily,
        fontWeight: style.fontWeight,
        lineHeight: style.lineHeight,
        letterSpacing: style.letterSpacing,
        textTransform: style.textTransform,
        textAlign: style.textAlign,
        color: style.color,
      }}
      id={track_id}
      ref={inputRef}
    >
      <input
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        aria-label={placeholder}
        inputMode={type === 'number' ? 'numeric' : 'text'}
        onInput={maxLength ? maxLengthCheck : undefined}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{
          display: 'block',
          width: '100%',
          height: style.height,
          boxSizing: 'border-box',
          borderRadius: '4px',
          border: errors ? '1px solid #f44336' : '1px solid #4A4A4A',
          backgroundColor: '#FEFEFE',
          boxShadow: '0px 8px 50px 0px #BCBCBC',
          padding: '10px 12px',
          fontSize: '16px',
          outline: 'none',
        }}
        onKeyPress={(event) => {
          if (type === 'number' && event.key === 'e') {
            event.preventDefault();
          }
        }}
        {...register}
      />
      <span
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: value || isFocused || defaultValue ? '11px' : '50%',
          top: value || isFocused || defaultValue ? placeholderTop : '50%',
          transform:
            value || isFocused || defaultValue
              ? 'none'
              : 'translate(-50%, -50%)',
          transition: 'all 0.6s ease-in-out',
          color: value || isFocused || defaultValue ? '#ccc' : '#4A4A4A',
          fontSize:
            value || isFocused || defaultValue ? '10px' : placeholderFontSize,
          textAlign: 'left',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 'calc(100% - 24px)',
        }}
      >
        {placeholder}
      </span>
      {errors && (
        <div
          style={{
            color: '#f44336',
            marginTop: '5px',
            fontSize: '14px',
            position: 'absolute',
          }}
        >
          {errorText}
        </div>
      )}
    </div>
  );
};
