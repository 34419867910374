export const paths = {
  home: '/',
  error: '/404',
  faqs: '/faq',
  quote: '/quote',
  quotes: '/quotes',
  benefits: '/benefits',
  plans: '/plans',
  privacy: '/privacy',
  aboutUs: '/about-us/',
  thankYou: '/thank_you',
  terms: '/terms-of-use',
  quoteFirstPage: '/quote/1',
};
