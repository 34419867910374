import { useState, FunctionComponent, useRef, useEffect } from 'react';
import { paths } from 'routes/index';
import { useRouter } from 'next/router';
import { useEmit } from '@/hooks/index';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { LayoutProps } from './Layout.props';
import { Fetching } from '@/views/Fetching/index';
import { ThemeChanger } from '@/themes/ThemeChanger';
import { ThemeProvider } from '@/themes/ThemeContext';

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const bodyRef = useRef<HTMLDivElement>(null);
  const headerHandler = (isLoading: boolean) => setIsLoading(isLoading);
  const isHidden = [
    paths.quote,
    paths.quotes,
    paths.thankYou,
    paths.plans,
    paths.benefits,
    '/token_test',
  ].includes(router.pathname.split('/').slice(0, 2).join('/'));

  useEmit({
    HIDE_HEADER: () => headerHandler(true),
    SHOW_HEADER: () => headerHandler(false),
  });

  useEffect(() => {
    if (typeof window === 'object') {
      window.scrollTo({
        top: 0,
      });
    }
  }, [isLoading]);
  return (
    <ThemeProvider>
      <div className="overflow-x-auto">
        {isLoading && <Fetching />}
        <div className={`${isLoading && 'hidden'}`}>
          <Header className={isHidden || isLoading ? 'hidden' : ''} />
          {/* <ThemeChanger /> */}

          <main ref={bodyRef} tabIndex={0} role="main">
            {children}
          </main>
          {isHidden || isLoading ? null : <Footer />}
        </div>
      </div>
    </ThemeProvider>
  );
};

export const withLayout = <T extends Record<string, unknown>>(
  Component: FunctionComponent<T>
) => {
  return function withLayoutComponent(props: T): JSX.Element {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };
};
